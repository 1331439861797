.video-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px solid #ccc;
    margin: auto;
    max-width: 90%;
}

.video-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
}


.video-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000;
    overflow: hidden; /* To prevent overflow of the caption */
    max-width: 100%; /* Ensure the item fits within the grid */
    height: auto;
    position: relative; /* Ensure child elements can be positioned absolutely */
}

.refresh-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

.video-viewer-buttons {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #463ca7;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.final-video-btn:hover {
    background-color: #3a3291;
  }

.spinning {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}