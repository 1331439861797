.frame-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px solid #ccc;
    margin: auto;
    max-width: 90%;
}

.frame-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;
}

.frame-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000;
    overflow: hidden; /* To prevent overflow of the caption */
    max-width: 100%; /* Ensure the item fits within the grid */
    height: auto;
    position: relative; /* Ensure child elements can be positioned absolutely */
}

.frame-item img {
    width: 100%;
    height: auto;
}

.frame-caption-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    padding: 5px 10px; /* Adjust padding to fit the content */
    box-sizing: border-box; /* Ensure padding is included in the width */
}

.frame-caption {
    background: none;
    border: none;
    color: white;
    width: 100%;
    height: 4.5em; /* Approximate height for 3 lines */
    resize: vertical; /* Allow vertical resizing */
    overflow: auto; /* Enable vertical scrolling */
    margin: 0;
    flex-grow: 1;
}

.refresh-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    margin-left: 5px;
    position: absolute; /* Position the button absolutely */
    right: 10px; /* Position it to the right edge */
    bottom: 10px; /* Position it to the bottom edge */
}

.confirm-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #463ca7;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.spinning {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}