.terms-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .terms-modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 80vh; /* Add max-height for vertical scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .terms-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .terms-modal-header h3 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .terms-modal-body p,
  .terms-modal-body ul,
  .terms-modal-body h2 {
    margin-bottom: 1em;
  }
  
  .terms-modal-body ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .terms-modal-body ul li {
    margin-bottom: 0.5em;
  }
  
  .terms-modal-body h2 {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
  }