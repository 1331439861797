/* Background overlay */
.modal-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 50;
}

/* Modal container */
.modal-container {
    background: #463CA7; /* Soft purple background */
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    max-width: 32rem;
    text-align: center;
    animation: fadeIn 0.3s ease;
    color: white; /* White text */
}

/* Modal title */
.modal-title {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: white;
}

/* Modal message */
.modal-message {
    color: #e0e0e0; /* Slightly lighter text for readability */
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}

/* Button container */
.button-group {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

/* Cancel button */
.cancel-button {
    padding: 0.5rem 1.5rem;
    background-color: #f4a825; /* Mustard color */
    color: white;
    font-weight: 600;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cancel-button:hover {
    background-color: #d38f20;
    transform: translateY(-2px);
}

/* Login button */
.login-button {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem;
    background-color: #f4a825; /* Mustard color */
    color: white;
    font-weight: 600;
    border-radius: 0.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.login-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(244, 168, 37, 0.4);
}

.login-button .google-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
}

/* Fade in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}