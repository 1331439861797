/* Ensures the warning message is hidden on larger screens */
.mobile-warning {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffcc00;
    color: #333;
    text-align: center;
    padding: 15px 20px;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    font-family: 'Arial', sans-serif; /* Clean and modern font */
  }
  
  /* Makes the warning message visible on smaller screens */
  @media only screen and (max-width: 768px) {
    .mobile-warning {
      display: block;
    }
  }
  
  /* Ensures the text inside the warning message scales well */
  .mobile-warning p {
    margin: 0;
    font-size: 1em; /* Adjust the font size as needed */
    line-height: 1.5;
  }
  
  /* Add some margin to the bottom to avoid overlap with other content */
  .mobile-warning + * {
    margin-top: 60px; /* Adjust based on the height of the warning message */
  }