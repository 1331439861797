@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.landing-page {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header */
.header {
  background-color: #463ca7;
  color: #fff;
  padding: 20px;
  text-align: left;
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 0px; /* Added margin-bottom */
}

/* Main Content */
.main-content {
  padding: 20px;
  flex-grow: 1;
}

/* Intro Section */
.intro {
  text-align: center;
  margin-bottom: 40px;
}

.intro h2 {
  font-size: 2.5em;
  font-weight: bold;
  margin-top: 1rem; /* Added margin-top */
}

.intro-subtitle {
  font-size: 1.5em;
  font-weight: normal;
  margin-top: 1.5rem; /* Added margin-top */
}

.script-input {
  width: 100%;
  max-width: 720px;
  padding: 10px;
  font-size: 1em;
  margin: 20px auto;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.relative-container {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  position: relative;
  border-radius: 8px;
}

/* Sidebar styling */
aside {
  width: 25%;
  padding: 20px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #463ca7;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

button:disabled {
  background-color: #aaa;
}

/* Inspiration Section */
.inspiration {
  text-align: center;
  margin-bottom: 40px;
}

.inspiration h3 {
  font-size: 1.5em;
  font-weight: bold;
}

.story-grid {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.story-card {
  width: calc(33.333% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

/* Wizard Container */
.wizard-container {
  margin: 40px 0;
}

/* Featured Videos */
.featured-videos {
  text-align: center;
  margin-bottom: 1.5rem; /* Added margin-bottom */
}

.featured-video {
  margin-top: 20px;
}

.featured-video video {
  max-width: 100%;
  max-height: 500px;
}

/* Footer */
.footer {
  background-color: #463ca7;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  font-size: 1em;
  font-weight: normal;
}

/* Aesthetics Selector */
.aesthetics-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid #ccc;
  margin: auto;
  max-width: 90%;
  text-align: center;
}

.aesthetics-selector h1 {
  margin-bottom: 20px;
}

.aesthetics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
}

.aesthetic-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #000;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.aesthetic-item:hover {
  transform: scale(1.05);
}

.aesthetic-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.aesthetic-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.aesthetic-info p {
  margin-right: 5px;
}

.company-logo {
  width: 32px;
  height: 32px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .story-card {
    width: 100%;
    margin-bottom: 10px;
  }

  .script-input {
    width: 100%;
  }
}

.responsive-image {
  @apply w-full h-auto block mb-0;
}

.default-image {
  @apply block;
}



@media (max-width: 768px) {
  .default-image {
    @apply hidden;
  }
  .mobile-image {
    @apply block;
  }
}

@media (min-width: 769px) {
  .mobile-image {
    @apply hidden;
  }
}