.final-video-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px solid #ccc;
    margin: auto;
    max-width: 90%;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  
  .final-video-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #463ca7;
    color: #fff;
    border: none;
    border-radius: 5px;
  }
  
  .final-video-btn:hover {
    background-color: #3a3291;
  }