.aesthetics-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px solid #ccc;
    margin: auto;
    max-width: 90%;
    text-align: center;
}

.aesthetics-selector h1 {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.4em;

}

.aesthetics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
}

.aesthetic-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.2s;
}

.aesthetic-item h3 {
    margin-bottom: 10px;
  }

.aesthetic-item:hover {
    transform: scale(1.05);
}

.aesthetic-item img {
    width: 100%;
    height: 250px; /* Set a fixed height */
    object-fit: cover; /* Ensure the image covers the container */
}

.aesthetic-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.aesthetic-info p {
    margin-right: 5px;
}

.company-logo {
    width: 32px;
    height: 32px;
    object-fit: contain; /* Ensures the logo scales correctly within the box */
}